import {
  downloadFileFromCloudStorageFileTransfer,
  LastUserReviewResponse,
  useAcknowledgeRejectedReviewCompliance,
  useGetLastRequestCompliance,
  UserReviewRequestType,
  UserReviewStatusType,
} from '@ping/api';
import CircleWarningIcon from '@ping/assets/Icon/circle-warning.svg';
import DownloadIcon from '@ping/assets/Icon/download.svg';
import FileIcon from '@ping/assets/Icon/file.svg';
import { ProvideRequestedDocumentsModal } from '@ping/components';
import { modalKeys } from '@ping/constants/modal-keys.constant';
import { t } from '@ping/helpers';
import { useCountUp, useRemoteModal } from '@ping/hooks';
import { uploadDocumentsModalRemoteStateSelector, useModalStore } from '@ping/stores/modal.store';
import {
  isUserAdminSelector,
  isUserComplianceReviewerSelector,
  isUserTradeDataViewerSelector,
  lastRequestIdSelector,
  useUserInformationStore,
} from '@ping/stores/userInformation.store';
import { Alert, Button, Modal, Spinner, Text } from '@ping/uikit';
import { getDuoNumber, isNil } from '@ping/utils';
import { formatDate } from '@ping/utils/format.util';
import { compliancyPendingRequestSeenStore } from '@ping/stores/compliancy-pending-request-seen.store';

import style from './style.module.scss';

interface DocumentItemProps {
  name: string;
  size: string;
  id: string;
}

const DocumentItem = ({ name, size, id }: DocumentItemProps) => {
  const isUserAdmin = useUserInformationStore(isUserAdminSelector);
  const isUserComplianceOfficer = useUserInformationStore(isUserComplianceReviewerSelector);
  const isUserTradeDataViewer = useUserInformationStore(isUserTradeDataViewerSelector);

  const handleDownloadFile = () => {
    downloadFileFromCloudStorageFileTransfer(id, {
      responseType: 'blob',
    }).then(res => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(res as unknown as Blob);
      link.download = name;
      link.click();
    });
  };

  return (
    <div className={style['document-item']}>
      <span className={style['document-item--icon']}>
        <FileIcon />
      </span>
      <div className={style['document-item--detail']}>
        <span className={style['document-item--name']}>{name ?? '--'}</span>
        <span className={style['document-item--size']}>{size ?? '--'}</span>
      </div>
      {(isUserAdmin || isUserComplianceOfficer || isUserTradeDataViewer) && (
        <button type='button' onClick={handleDownloadFile} className={style['document-item--download-link']}>
          <DownloadIcon />
        </button>
      )}
    </div>
  );
};

export const PendingApprovalModal = () => {
  const pendingApprovalModal = useRemoteModal(modalKeys.PENDING_APPROVAL);
  const { mutate: acknowledgeRejectedReviewMutate } = useAcknowledgeRejectedReviewCompliance();
  const { setCompliancyPendingRequestSeen } = compliancyPendingRequestSeenStore;
  const lastRequestId = useUserInformationStore(lastRequestIdSelector);
  const uploadDocumentsModal = useModalStore(uploadDocumentsModalRemoteStateSelector);
  const { data: activeRequestData, isLoading: isActiveRequestDataLoading } =
    useGetLastRequestCompliance<LastUserReviewResponse>({
      query: {
        enabled: pendingApprovalModal.state.isOpen,
      },
    });
  const isDocumentsRejected = activeRequestData?.statusType === UserReviewStatusType.Denied;
  const canUploadDocuments = activeRequestData?.statusType === UserReviewStatusType.Open;
  const mustProvideMoreDocuments = !isNil(activeRequestData?.comment);
  const createdAtTimeInMillisecond = new Date(activeRequestData?.readyToReviewAt).getTime();
  const time = useCountUp(createdAtTimeInMillisecond);

  const handleShowUploadDialog = () => {
    uploadDocumentsModal.open();
    pendingApprovalModal.state.close();
  };

  const handleCloseModalPermanently = () => {
    setCompliancyPendingRequestSeen(true);
    acknowledgeRejectedReviewMutate({ data: { requestId: lastRequestId } });
    pendingApprovalModal.state.close();
  };

  const renderTitle = () => {
    if (isDocumentsRejected) {
      return t('Request denied');
    }

    // TODO (Backend): There is a request to change the title from Tier2 to Tier1 whenever the UserReviewRequestType is HighRiskToTier2.
    // It will be fixed by the backend next week
    return t(
      'Pending approval for Tier %s',
      activeRequestData?.requestType === UserReviewRequestType.HighRiskToTier2
        ? 1
        : activeRequestData?.requestType?.charAt(activeRequestData.requestType.length - 1)
    );
  };

  const renderContent = () => {
    if (isDocumentsRejected) {
      return (
        <>
          <Text caption='regular' className={style['rejected--title']}>
            {activeRequestData?.comment}
          </Text>
          {/*<ul className={style['rejected--reasons']}>*/}
          {/*  <li>The user has a funny name</li>*/}
          {/*  <li>The user can’t pronounce “murciélago”</li>*/}
          {/*  <li>The user talks while eating</li>*/}
          {/*</ul>*/}
        </>
      );
    }
    return (
      <>
        {canUploadDocuments && mustProvideMoreDocuments && (
          <Alert.Root className={style['more-documents--alert']} variant='warning'>
            <Alert.Icon>
              <CircleWarningIcon className={style['pending-approval-modal__warning-icon']} />
            </Alert.Icon>
            <Alert.Title>{t('More documents required')}</Alert.Title>
            <Alert.Description>{activeRequestData?.comment}</Alert.Description>
          </Alert.Root>
        )}
        <div className={style['pending-approval-modal__details']}>
          <div className={style['detail-item']}>
            <span className={style['detail-item--title']}>{t('Submitted for approval')}</span>
            <span className={style['detail-item--value']}>
              {formatDate(activeRequestData?.readyToReviewAt) ?? '--'}
            </span>
          </div>
          <div className={style['detail-item']}>
            <span className={style['detail-item--title']}>{t('Time waiting')}</span>
            <span className={style['detail-item--value']}>
              {isActiveRequestDataLoading || (createdAtTimeInMillisecond && isNaN(time.seconds)) ? (
                <Spinner size={7} />
              ) : createdAtTimeInMillisecond ? (
                <>
                  {time.days > 0 && `${time.days}d `}
                  {time.hours > 0 && `${getDuoNumber(time.hours)}h `}
                  {time.minutes > 0 && `${getDuoNumber(time.minutes)}m `}
                  {time.seconds >= 0 && `${getDuoNumber(time.seconds)}s`}
                </>
              ) : (
                '--'
              )}
            </span>
          </div>
        </div>
        <div className={style['separator-line']} />
        <div className={style['pending-approval-modal__documents']}>
          <Text className={style['documents-title']} body='semi-bold'>
            {t('Previous uploads')}
          </Text>
          <div className={style['documents-list']}>
            {activeRequestData?.uploads?.map(fileItem => (
              <DocumentItem
                key={fileItem.fileId}
                name={fileItem.fileName}
                size={fileItem.fileSize}
                id={fileItem.fileId}
              />
            ))}
          </div>
        </div>
      </>
    );
  };

  const renderActions = () => {
    if (isDocumentsRejected) {
      return (
        <Button onClick={handleCloseModalPermanently} size='large' uppercase>
          {t('I understand')}
        </Button>
      );
    }
    if (mustProvideMoreDocuments) {
      return <ProvideRequestedDocumentsModal.Trigger onPress={pendingApprovalModal.state.close} />;
    }
    if (canUploadDocuments) {
      return (
        <Button onClick={handleShowUploadDialog} size='large' uppercase>
          {t('Upload new document')}
        </Button>
      );
    }
    return null;
  };

  return (
    <>
      <Modal.Root {...pendingApprovalModal.props}>
        <Modal.Portal className={style['pending-approval-modal']} divide>
          <Modal.Header className={style['pending-approval-modal__header']}>
            <Modal.Title>{renderTitle()}</Modal.Title>
            <Modal.CloseButton className={style['pending-approval-modal__close']} />
          </Modal.Header>
          <Modal.Content className={style['pending-approval-modal__content']}>{renderContent()}</Modal.Content>
          {(canUploadDocuments || isDocumentsRejected || mustProvideMoreDocuments) && (
            <Modal.Actions className={style['pending-approval-modal__actions']}>{renderActions()}</Modal.Actions>
          )}
        </Modal.Portal>
      </Modal.Root>

      <ProvideRequestedDocumentsModal.Portal comment={activeRequestData?.comment} />
    </>
  );
};
