import { Store } from '@ping/utils';

interface ICompliancyPendingRequestSeen {
  value: boolean;
}

// --------------------------------------------------------------------------------
// COMPLIANCY PENDING REQUEST SEEN STORE
// --------------------------------------------------------------------------------
export const useCompliancyPendingRequestSeenStore = new Store<ICompliancyPendingRequestSeen>({ value: false })
  .withPersist({ name: 'COMPLIANCY_PENDING_REQUEST_SEEN_STORE', getStorage: () => window.localStorage })
  .withDevTools()
  .build();

// --------------------------------------------------------------------------------
// COMPLIANCY PENDING REQUEST SEEN SELECTORS
// --------------------------------------------------------------------------------
export const isCompliancyPendingRequestSeenSelector = state => state.value;

// --------------------------------------------------------------------------------
// COMPLIANCY PENDING REQUEST SEEN API
// --------------------------------------------------------------------------------
export const compliancyPendingRequestSeenStore = {
  setCompliancyPendingRequestSeen: (value: boolean) => useCompliancyPendingRequestSeenStore.setState({ value }),
};
