import { Modal } from '@ping/uikit';
import { ReactElement } from 'react';
import NavbarDropdownItems, { TNavbarDropdownItem } from '@ping/components/Header/NavbarDropdownItems';
import { PendingApprovalTriggerButton } from '@ping/components/Header/PendingApprovalTriggerButton';
import type { IModalContext } from '@ping/uikit';

import style from './style.module.scss';

interface IProps {
  title: ReactElement;
  link: TNavbarDropdownItem[];
}

export const MobileUserMenuModalTrigger = ({ title, link }: IProps) => {
  const handleOnClose = (ctx: IModalContext) => {
    ctx.state.close();
  };
  return (
    <Modal.Root>
      <Modal.Context.Consumer>
        {ctx => (
          <>
            <Modal.Trigger className={style['trade-drop-down-modal-trigger']}>{title}</Modal.Trigger>

            <Modal.Portal className={style['trade-drop-down-modal']} isDismissable>
              <div className={style['trade-drop-down-modal__divider']}>
                <hr />
              </div>
              <Modal.Content className={style['trade-drop-down-modal__content']}>
                <br />
                <PendingApprovalTriggerButton />
                <NavbarDropdownItems
                  className={style['header__dropdown-item']}
                  options={link}
                  onLinkClick={() => handleOnClose(ctx)}
                />
              </Modal.Content>
            </Modal.Portal>
          </>
        )}
      </Modal.Context.Consumer>
    </Modal.Root>
  );
};
